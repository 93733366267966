import { SupportedLocale, SupportedRegion } from "../../consts/locale";

export interface LocaleMenuItem {
  title: string;
  icon: SupportedLocale;
  locale: SupportedLocale;
  region: SupportedRegion;
}

export const supportedLocaleItems: LocaleMenuItem[] = [
  {
    title: "English (EN)",
    icon: SupportedLocale.EN,
    locale: SupportedLocale.EN,
    region: SupportedRegion.US,
  },
  {
    title: "French (FR)",
    icon: SupportedLocale.FR,
    locale: SupportedLocale.FR,
    region: SupportedRegion.EU,
  },
];

const myAccountEULink = process.env.NEXT_PUBLIC_EU_MY_ACCOUNT;

export interface AccountMenuItem {
  title: string;
  link: string;
}

export const accountItems: AccountMenuItem[] = [
  {
    title: "My Account",
    link: `${myAccountEULink}`,
  },
  {
    title: "My Information",
    link: `${myAccountEULink}/my-information`,
  },
  {
    title: "Sales History",
    link: `${myAccountEULink}/sales-history`,
  },
  {
    title: "Payment Settings",
    link: `${myAccountEULink}/payment-settings`,
  },
  {
    title: "Quotes",
    link: `${myAccountEULink}/quotes`,
  },
  {
    title: "Sign out",
    link: `${myAccountEULink}/sign-out`,
  },
];
