import React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

const Bold = ({ children, className }): any => (
  <span className={className}>{children}</span>
);
const Text = ({ children, className }): any => (
  <p className={className}>{children}</p>
);

export const optionsSYB = {
  renderMark: {
    [MARKS.BOLD]: (text): any => <Bold className="bold">{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children): any => (
      <p className="body-lg landing-text">{children}</p>
    ),
    [BLOCKS.HEADING_2]: (node, children): any => (
      <h2 className="landing-header">{children}</h2>
    ),
    [BLOCKS.UL_LIST]: (node, children): any => (
      <ul className="buyout-list">{children}</ul>
    ),
  },
};

export const optionsLegalText = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children): any => (
      <p className="terms-and-cond">{children}</p>
    ),
  },
};

export const optionsModal = {
  renderMark: {
    [MARKS.BOLD]: (text): any => <Bold className="bold">{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children): any => (
      <p className="modal-text">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children): any => (
      <ul className="modal-list">{children}</ul>
    ),
    [BLOCKS.HEADING_3]: (node, children): any => (
      <h3 className="modal-header">{children}</h3>
    ),
  },
};

export const optionsRotator = {
  renderMark: {
    [MARKS.BOLD]: (text): any => <Bold className="bold">{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children): any => (
      <Text className="excerpt">{children}</Text>
    ),
  },
  renderText: (text): any => text.replace("!", "?"),
};
