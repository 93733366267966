import React from "react";
import { Header } from "@fashionphile/component-library";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { accountData } from "../../helpers/layout/header";
import {
  sellDropDownLinks,
  desktopLinks,
} from "../../helpers/layout/headerRotator";
import { optionsRotator } from "../../helpers/contentful/options";
import { BaseContentfulPage } from "../../helpers/contentful/getBaseContentfulPageData";
import { getCartContext } from "../context/CartContext";

interface Props extends Partial<BaseContentfulPage> {
  stickyHeader?: boolean;
}

const MainHeader: React.FC<Props> = ({
  rotatorData,
  footerData,
  navData,
  stickyHeader = false,
}) => {
  const { isLoggedIn, cartAmount } = getCartContext();

  const handleSearch = (value: string): void => {
    window.parent.location.href = `${process.env.NEXT_PUBLIC_WEB_APP_URL}/shop?search=${value}`;
  };

  const rotatorContent =
    rotatorData &&
    rotatorData.map((rotator1, key) => (
      <div key={`option-${key}`} className="rotator">
        {documentToReactComponents(rotator1, optionsRotator)}
      </div>
    ));

  return (
    <header
      className="header"
      style={stickyHeader ? {} : { position: "static" }}
    >
      <Header
        stickyHeader={stickyHeader}
        ariaLabel="Header"
        navData={navData}
        accountData={accountData}
        domain={process.env.NEXT_PUBLIC_WEB_APP_URL}
        sellDomain={process.env.NEXT_PUBLIC_HOST}
        secureDomain={process.env.NEXT_PUBLIC_SECURE_URL}
        cartCount={cartAmount}
        rotatorData={rotatorContent}
        desktopLinks={desktopLinks}
        sellDropDownLinks={sellDropDownLinks}
        loggedIn={isLoggedIn}
        handleSearch={handleSearch}
        rotatorClassName="rotatorSvgFix"
        searchClassName="searchSvgFix"
        mobileNavClassName="mobileHeaderFix"
      />
    </header>
  );
};

export default MainHeader;
