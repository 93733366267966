import React from "react";
import { Icon } from "@fashionphile/component-library";
// import styles from "./langSelect.module.scss";

const LangSelect = (props): React.ReactElement => {
  const { text, icon, dropdown, langDropdown, currentLanguage } = props;

  return (
    <>
      <img
        src={`/supplier-portal/icons/language-${icon}.svg`}
        className="languageIcon"
        alt="Language icon"
      />
      {dropdown ? (
        <p className="dropdownText">{text}</p>
      ) : (
        <p className={icon === currentLanguage ? "activeLanguage" : "text"}>
          {text}
        </p>
      )}
      {dropdown && (
        <Icon
          className="caretDown"
          size="xxs"
          name={langDropdown ? "icon-caret-up" : "icon-caret-down"}
        />
      )}
    </>
  );
};

export default LangSelect;
