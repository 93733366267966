import React from "react";
import { CookieFooter } from "@fashionphile/component-library";
import { SimplifiedCookieFooterProps } from "./SimplifiedCookieFooter.props";

const SimplifiedCookieFooter: React.FunctionComponent<SimplifiedCookieFooterProps> = (
  props
) => {
  const { cookieFooterData } = props;

  if (!cookieFooterData) {
    return null;
  }

  return (
    <CookieFooter
      ariaLabel={cookieFooterData.ariaLabel}
      headerToDisplay={cookieFooterData.headerToDisplay}
      descriptionTextToDisplay={cookieFooterData.descriptionTextToDisplay}
      buttonText={cookieFooterData.buttonText}
      linkTextToDisplay={cookieFooterData.linkTextToDisplay}
      link={cookieFooterData.link}
    />
  );
};

export default SimplifiedCookieFooter;
