/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const parseFooterData = (data) =>
  data.map((footerObj) => {
    const footerFieldObj = {
      title: footerObj.header,
      list: footerObj.content,
    };

    footerFieldObj.list = footerFieldObj.list.map((item) => {
      const columnItems = {
        name: item.text,
        link:
          item.link[0] === "/"
            ? `${process.env.NEXT_PUBLIC_WEB_APP_URL}${item.link}`
            : item.link,
      };
      return columnItems;
    });

    return footerFieldObj;
  });

export const socialIcons = [
  {
    ariaLabel: "Instagram",
    href: "//instagram.com/fashionphile",
    target: "_blank",
    rel: "noreferrer",
    iconName: "icon-brand-instagram",
  },
  {
    ariaLabel: "Facebook",
    href: "//www.facebook.com/fashionphile",
    target: "_blank",
    rel: "noreferrer",
    iconName: "icon-brand-facebook-f",
  },
  {
    ariaLabel: "Pinterest",
    href: "//www.pinterest.com/fashionphile/",
    target: "_blank",
    rel: "noreferrer",
    iconName: "icon-brand-pinterest",
  },
  {
    ariaLabel: "Twitter",
    href: "//twitter.com/fashionphile",
    target: "_blank",
    rel: "noreferrer",
    iconName: "icon-brand-twitter",
  },
  {
    ariaLabel: "Fashionphile",
    href: "//fashionphile.com",
    target: "_blank",
    rel: "noreferrer",
    iconName: "icon-logo-knot",
  },
];

const webAppUrl = `${process.env.NEXT_PUBLIC_WEB_APP_URL}`;
export const navLinks = [
  {
    title: "Accessibility Statement",
    href: `${webAppUrl}/accessibility-statement/page`,
    target: "_new",
  },
  {
    title: "Privacy Policy",
    href: `${webAppUrl}/privacy/page`,
    target: "_new",
  },
  {
    title: "CCPA Info",
    href: `${webAppUrl}/ccpa`,
    target: "_new",
  },

  {
    title: "Seller terms",
    href: `${webAppUrl}/seller-terms-and-conditions`,
    target: "_new",
  },
  {
    title: "Terms of use",
    href: `${webAppUrl}/terms-of-use/page`,
    target: "_new",
  },
];
