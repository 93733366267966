import React, { useContext } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
  resetNextUuid,
} from "react-accessible-accordion";
import { Icon } from "@fashionphile/component-library";
import { TranslateContext } from "../../../context/TranslateContext";
import { SupportedLocale } from "../../../../helpers/consts/locale";

const NavAccordion = ({
  ariaLabel,
  nested,
  hideClass,
  currentLanguage,
  toggleLanguage,
}): React.ReactElement => {
  const _translate = useContext(TranslateContext);
  const { translate, contextLocale } = _translate;

  const accordionClass = `accordionBlack accordion`;
  const cardClass = nested
    ? `card cardTwo accordion__item`
    : `card accordion__item`;
  const itemHeadingClass = nested
    ? `accordionHeading subhed accordion__heading`
    : `accordionHeading accordion__heading`;

  // Reset uuid
  resetNextUuid();

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      className={hideClass ? "accordion" : accordionClass}
      data-testid="nav-accordion"
    >
      <AccordionItem className={cardClass} data-testid="accordion-item">
        <AccordionItemHeading
          className={itemHeadingClass}
          data-testid="accordion-item-heading"
        >
          <AccordionItemButton className="accordionButton accordion__button">
            <span className="text body-lg">{translate("Change language")}</span>
            <AccordionItemState>
              {(state): React.ReactElement => (
                <div className="currentLanguageWrapper">
                  <img
                    src={`/supplier-portal/icons/language-${contextLocale}.svg`}
                    className="languageIcon"
                    alt="Language icon"
                  />
                  <span className="currentLanguageMobile body-lg">
                    {contextLocale.toUpperCase()}

                    <Icon
                      variant="primary"
                      size="xxs"
                      name={
                        state.expanded ? "icon-caret-up" : "icon-caret-down"
                      }
                      isClickable
                    />
                  </span>
                </div>
              )}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="accordionPanel accordion__panel">
          <ul className="navLinks">
            <div
              className={
                contextLocale === SupportedLocale.EN
                  ? `activeLanguage body-lg`
                  : "body-lg"
              }
              data-testid="new-arrivals"
              onClick={(): void => toggleLanguage(SupportedLocale.EN)}
              onKeyPress={(): void => toggleLanguage(SupportedLocale.EN)}
              tabIndex={0}
            >
              <img
                src="icons/language-en.svg"
                className="languageIconOptions"
                alt="Language icon"
              />
              {translate("English (EN)")}
            </div>
            <div
              className={
                currentLanguage === SupportedLocale.FR
                  ? `activeLanguage body-lg`
                  : "body-lg"
              }
              data-testid="new-arrivals"
              onClick={(): void => toggleLanguage(SupportedLocale.FR)}
              onKeyPress={(): void => toggleLanguage(SupportedLocale.FR)}
              tabIndex={0}
            >
              <img
                src="icons/language-fr.svg"
                className="languageIconOptions"
                alt="Language icon"
              />
              {translate("French (FR)")}
            </div>
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default NavAccordion;
