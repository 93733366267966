import { AccountNav } from "@fashionphile/component-library/build/Components/MobileNavigation/MobileNavigation.types";

const accountURL = process.env.NEXT_PUBLIC_ACCOUNT_URL;
export const accountData: AccountNav[] = [
  {
    title: "My Account",
    header: "My Account",
    content: [
      {
        text: "Dashboard",
        link: `${accountURL}/account/dashboard`,
      },
      {
        text: "Alerts",
        link: `${accountURL}/account/alerts`,
      },
      {
        text: "My Purchases",
        link: `${accountURL}/account/purchases`,
      },
      {
        text: "My Quotes",
        link: `${accountURL}/account/sales/quotes`,
      },
      {
        text: "My Sales",
        link: `${accountURL}/account/sales/products`,
      },
    ],
  },
];
