import React, { useState } from "react";
import Link from "next/link";
import {
  supportedLocaleItems,
  accountItems,
} from "../../../../helpers/layout/simplifiedLayout/menuItems";
import LangSelect from "./LangSelect";

const Dropdown = (props): React.ReactElement => {
  const { accountDropdown, toggleLanguage, currentLanguage } = props;
  const [click, setClick] = useState(false);

  return (
    <ul
      className={
        click
          ? `dropdownMenuClicked ${accountDropdown && "account"}`
          : `dropdownMenu ${accountDropdown && "account"}`
      }
    >
      <div className="dropdownWrapper">
        {accountDropdown
          ? accountItems.map((item, index) => (
              <li key={index} className="dropDownAccount">
                <Link href={item.link}>{item.title}</Link>
              </li>
            ))
          : supportedLocaleItems.map((item, index) => (
              <li key={index} className="dropDownLanguages">
                <div
                  onClick={(): void => toggleLanguage(item.icon)}
                  onKeyDown={(): void => toggleLanguage(item.icon)}
                  tabIndex={0}
                >
                  <LangSelect
                    locale={item.locale}
                    text={item.title}
                    icon={item.icon}
                    dropdown={false}
                    toggleLanguage={toggleLanguage}
                    currentLanguage={currentLanguage}
                  />
                </div>
              </li>
            ))}
      </div>
    </ul>
  );
};

export default Dropdown;
