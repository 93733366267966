import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { COOKIES } from "../../events/cookies";
import {
  MappedFooterData,
  MappedNavData,
} from "../../helpers/layout/getHeaderFooterProps";
import { isRegionEU } from "../../helpers/consts/locale";
import MainFooter from "./MainFooter";
import MainHeader from "./MainHeader";
import { SimplifiedCookieFooterProps } from "./SimplifiedLayout/SimplifiedCookieFooter/SimplifiedCookieFooter.props";
import SimplifiedFooter from "./SimplifiedLayout/SimplifiedFooter/SimplifiedFooter";
import SimplifiedHeader from "./SimplifiedLayout/SimplifiedHeader/SimplifiedHeader";

interface USLayoutProps {
  footerData: MappedFooterData[];
  navData: MappedNavData[];
  rotatorData: string[];
  stickyHeader?: boolean;
}

type EULayoutProps = SimplifiedCookieFooterProps;

export type LayoutProps = Partial<USLayoutProps & EULayoutProps>;

const LayoutHeader: React.FC<LayoutProps> = (props) =>
  isRegionEU ? <SimplifiedHeader /> : <MainHeader {...props} />;

const LayoutFooter: React.FC<LayoutProps> = ({
  cookieFooterData,
  footerData,
}) =>
  isRegionEU ? (
    <SimplifiedFooter cookieFooterData={cookieFooterData} />
  ) : (
    <MainFooter footerData={footerData} cookieFooterData={cookieFooterData} />
  );

const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [isMobileApp, setIsMobileApp] = useState(false);

  useEffect(() => {
    setIsMobileApp(
      Cookies.get(COOKIES.MOBILE_APP) === "1" ||
        process.env.NEXT_PUBLIC_MOBILE_APP_FORCE === "true"
    );
    setLoaded(true);
  }, [loaded, COOKIES.MOBILE_APP]);

  return (
    <div style={{ opacity: `${loaded ? 1 : 0}` }}>
      <main>
        {!isMobileApp && <LayoutHeader {...props} />}
        {children}
        {!isMobileApp && <LayoutFooter {...props} />}
      </main>
    </div>
  );
};

export default Layout;
