import React, { useState } from "react";
import { Footer } from "@fashionphile/component-library";
import axios from "axios";
import SimplifiedCookieFooter from "../../components/Layouts/SimplifiedLayout/SimplifiedCookieFooter/SimplifiedCookieFooter";
import { socialIcons, parseFooterData } from "../../helpers/layout/footer";

interface FooterState {
  submissionEvent: boolean;
  emailError: string;
  email: string;
}

const validateEmail = (email: string, errCb: (err: string) => any): boolean => {
  const validEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
  if (!email) {
    errCb("Please enter an email address");
    return false;
  }
  if (validEmail.test(email) === false) {
    errCb("Please enter a valid email address");
    return false;
  }
  return true;
};

const MainFooter = (props: {
  footerData: any;
  cookieFooterData: any;
}): React.ReactElement => {
  const { footerData, cookieFooterData } = props;
  const columns = footerData?.slice(0, 4);
  const parsedColumns = parseFooterData(columns);

  const [state, setState] = useState<FooterState>({
    submissionEvent: false,
    emailError: "",
    email: "",
  });
  const updateState = (newState: Partial<FooterState>): void =>
    setState((state) => ({ ...state, ...newState }));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const email = e.target?.value || "";
    updateState({ email, emailError: "" });
  };

  const handleSubmit = async (): Promise<any> => {
    const { email } = state;
    const isValid = validateEmail(email, (emailError) =>
      updateState({ emailError })
    );

    if (!isValid) {
      return;
    }

    const url =
      "https://s9m1gx5mgl.execute-api.us-west-2.amazonaws.com/newsletter/subscribe";
    const data = {
      email,
      blog_subscription: 0,
      newsletter_subscription: 1,
    };

    axios
      .post(url, data)
      .then(() => {
        updateState({
          submissionEvent: true,
          email: "",
          emailError: "",
        });
      })
      .catch(() => {
        updateState({ emailError: "Something went wrong. Please try again" });
      })
      .finally(() => {
        setTimeout(() => {
          updateState({ submissionEvent: false });
        }, 3000);
      });
  };

  const { submissionEvent, email, emailError } = state;
  return (
    <>
      <SimplifiedCookieFooter cookieFooterData={cookieFooterData} />
      <Footer
        id="footer"
        ariaLabel="Footer"
        columnsData={parsedColumns}
        socialIcons={socialIcons}
        buttonSubmission={submissionEvent}
        buttonAriaLabel="Button"
        inputAriaLabel="Input"
        value={email}
        error={emailError}
        socialsHeader="Follow us"
        inputBaseHeader="Subscribe to our newsletter"
        subscriptionMessage="You've successfully subscribed!"
        placeholder="Email address"
        onChange={handleChange}
        buttonOnClick={handleSubmit}
      />
    </>
  );
};

export default MainFooter;
