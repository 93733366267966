import React, { useContext } from "react";
import Link from "next/link";
import SimplifiedCookieFooter from "../SimplifiedCookieFooter/SimplifiedCookieFooter";
import { TranslateContext } from "../../../context/TranslateContext";
import { SimplifiedCookieFooterProps } from "../SimplifiedCookieFooter/SimplifiedCookieFooter.props";

const SimplifiedFooter: React.FC<SimplifiedCookieFooterProps> = ({
  cookieFooterData,
}) => {
  const { translate } = useContext(TranslateContext);

  return (
    <div className="footer body-md">
      <SimplifiedCookieFooter cookieFooterData={cookieFooterData} />
      <div className="mainLinks">
        <a className="standalone-link" href="https://help.fashionphile.com/s/">
          FAQs
        </a>
        <Link href="/designer-directory">
          <a tabIndex={0} className="standalone-link">
            {translate("Designer directory")}
          </a>
        </Link>
        <a
          className="standalone-link"
          href="https://help.fashionphile.com/s/seller-terms-and-conditions"
        >
          {translate("Terms & conditions")}
        </a>
        <a
          className="standalone-link"
          href="https://help.fashionphile.com/s/privacy-policy"
        >
          {translate("Privacy policy")}
        </a>
      </div>
      <div className="logo">© 2023 FASHIONPHILE Group, LLC</div>
    </div>
  );
};

export default SimplifiedFooter;
