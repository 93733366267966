import { DesktopLinks } from "@fashionphile/component-library/build/Components/Rotator/Rotator.types";

export const sellDropDownLinks = [
  {
    name: "Submit item for quote",
    link: "/sell-your-bag",
  },
  {
    name: "Schedule a Virtual Appointment",
    link: "/virtual-appointment",
  },
  // {
  //   name: "Schedule White Glove Service",
  //   link: "/white-glove",
  // },
  {
    name: "Find a selling location",
    link: `${process.env.NEXT_PUBLIC_HOST}/locations`,
  },
];
// attention!! Order of this links affects My account dropdown behavior
const accountURL = process.env.NEXT_PUBLIC_ACCOUNT_URL;
export const desktopLinks: DesktopLinks[] = [
  {
    link: `/sell-with-us`,
    ariaLabel: "Sell Link",
    name: "Sell",
  },
  {
    link: `${accountURL}/account/followed-products`,
    ariaLabel: "Following Link",
    name: "Following",
    loggedIn: true,
  },
  {
    link: `${accountURL}/alerts`,
    ariaLabel: "Alert Link",
    name: "Alerts",
    loggedIn: false,
  },
  {
    link: `${accountURL}/account/dashboard`,
    ariaLabel: "Profile Link",
    name: "My Account",
    loggedIn: true,
  },
  {
    link: `${accountURL}/login`,
    ariaLabel: "Login Link",
    name: "Sign in",
    loggedIn: false,
  },
  {
    link: `/shopping-bag`,
    ariaLabel: "Shopping Bag Link",
    name: "Shopping Bag",
  },
];
