import React, { useState, useRef, useEffect, useContext } from "react";
import { Icon } from "@fashionphile/component-library";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { getUserContext } from "../../../context/UserContext";
import { TranslateContext } from "../../../context/TranslateContext";
import Dropdown from "./Dropdown";
import MobileNavigation from "./MobileNavigation";

const SimplifiedHeader = (): React.ReactElement => {
  // ideally this will be coming from parent, passing down as a prop to Header
  const [loggedInToken, setLoggedInToken] = useState(false);
  const token = Cookies.get("port_key_j");

  useEffect(() => {
    if (token) {
      setLoggedInToken(true);
    }
  }, []);
  const node = useRef();
  const router = useRouter();

  const [langDropdown, setLangDropdown] = useState(false);
  const [accDropdown, setAccDropdown] = useState(false);

  const translateContext = useContext(TranslateContext);
  const {
    user: { firstName },
  } = getUserContext();

  const { updateLocale, contextLocale, translate } = translateContext;

  const toggleLanguage = (language): void => {
    updateLocale(language);
    router.push(`/${language}${router.pathname}`, undefined, { shallow: true });
  };

  return (
    <div className="header">
      <nav className="navBar">
        <ul className="navMenu">
          <div className="navBarLogo">
            <a href="/sell-your-bag">
              <img
                src="https://www.fashionphile.com/images/FASHIONPHILE.svg"
                alt="FASHIONPHILE"
                className="companyLogo"
              />
              <span className="body-lg">EU</span>
            </a>
          </div>

          <li
            className="body-lg languageDropdown"
            ref={node}
            onMouseOver={(): void => setLangDropdown(true)}
            onMouseLeave={(): void => setLangDropdown(false)}
            onFocus={(): void => setLangDropdown(true)}
          >
            {translate("Change language")}
            <img
              src={`/supplier-portal/icons/language-${contextLocale}.svg`}
              className="languageIcon"
              alt="Language icon"
            />
            <p className="dropdownText">
              {contextLocale === "en" ? "EN" : "FR"}
            </p>
            <Icon
              className="caretDown"
              size="xxs"
              name={langDropdown ? "icon-caret-up" : "icon-caret-down"}
            />
            {langDropdown && (
              <Dropdown
                toggleLanguage={toggleLanguage}
                currentLanguage={contextLocale}
              />
            )}
          </li>

          {loggedInToken ? (
            <li
              ref={node}
              className="body-lg accountDropdown"
              onMouseOver={(): void => setAccDropdown(true)}
              onMouseLeave={(): void => setAccDropdown(false)}
              onFocus={(): void => setAccDropdown(true)}
            >
              Hi, {firstName}
              <Icon
                size="xxs"
                className="caretDownIcon"
                name={accDropdown ? "icon-caret-up" : "icon-caret-down"}
              />
              {accDropdown && <Dropdown accountDropdown />}
            </li>
          ) : (
            <li className="body-lg accountDropdown">
              <a href={`${process.env.NEXT_PUBLIC_EU_MY_ACCOUNT}/auth`}>
                {translate("Sign in or register")}
              </a>
            </li>
          )}
        </ul>
      </nav>
      <div className="mobileHeader">
        <MobileNavigation
          isLoggedIn={loggedInToken}
          currentLanguage={contextLocale}
          toggleLanguage={toggleLanguage}
        />
      </div>
      <hr className="bottomBorder" />
    </div>
  );
};

export default SimplifiedHeader;
