import React, { useState } from "react";
// import styles from "./MobileNavigation.module.scss";
import { accountItems } from "../../../../helpers/layout/simplifiedLayout/menuItems";
import { getUserContext } from "../../../context/UserContext";
import NavAccordion from "./NavAccordion";

const MobileNavigation = (props): React.ReactElement => {
  const { isLoggedIn, currentLanguage, toggleLanguage } = props;
  const [customText, setCustomText] = useState({
    sell: "Sell",
    signIn: "Sign In",
    signOut: "Sign Out",
    newArrivals: "New Arrivals",
    alerts: "Alerts",
    searchLabel: "Search for...",
    toggleLabel: "Toggle Navigation",
  });
  const [isOpen, openMenu] = useState(false);
  const [isOpenHamb, openMenuHamb] = useState(false);
  const [visible, showVisible] = useState(false);
  const colClassLeft = `mobileColLeft col-7`;
  const colRow = `mobileRow row`;
  const navClass = `mobileNavCont ${visible ? `show` : ""}`;
  const hamburgerIconClass = isOpenHamb
    ? `hamburgerIcon open`
    : `hamburgerIcon`;
  const mobileHeaderClass = `mobileHeader container open`;
  const {
    user: { firstName },
  } = getUserContext();
  const myAccountEULink = process.env.NEXT_PUBLIC_EU_MY_ACCOUNT;
  const supplierPortalEULink = process.env.NEXT_PUBLIC_EU_PORTAL;

  const handleOpenMenu = (): void => {
    const newOpen = !isOpen;
    openMenuHamb(!isOpenHamb);
    if (newOpen) {
      openMenu(newOpen);
      window.setTimeout(() => {
        showVisible(!visible);
      }, 100);
    } else {
      showVisible(!visible);
      window.setTimeout(() => {
        openMenu(newOpen);
      }, 500);
    }
  };

  return (
    <div className="mobileHeaderCont" data-testid="mobile-header">
      <div className={mobileHeaderClass}>
        <div className={colRow}>
          <div className={colClassLeft}>
            <button
              className={hamburgerIconClass}
              onClick={handleOpenMenu}
              type="button"
              aria-label="Toggle Navigation"
              data-testid="toggle-navigation"
              aria-expanded={isOpen}
            >
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              {customText.toggleLabel}
            </button>
            <a className="logo" href={`${supplierPortalEULink}`}>
              <img
                src="https://www.fashionphile.com/images/FASHIONPHILE.svg"
                alt="FASHIONPHILE"
              />
            </a>
          </div>
        </div>
        <nav className={navClass} aria-label="aria" data-testid="Navigation">
          <div className="topNavPortion">
            <div className="newArrivals">
              <a
                href={`${supplierPortalEULink}`}
                className="body-lg quoteLink"
                data-testid="new-arrivals"
              >
                Get a quote
              </a>
            </div>
            <div className="signIn alerts">
              <NavAccordion
                ariaLabel="Language Toggle Dropdown"
                nested={false}
                hideClass={false}
                currentLanguage={currentLanguage}
                toggleLanguage={toggleLanguage}
              />

              {isLoggedIn ? (
                <>
                  <a tabIndex={0} className="body-lg loggedInText">
                    Hi, {firstName}
                  </a>
                  {accountItems.map((item, index) => (
                    <a
                      key={index}
                      href={item.link}
                      className="body-lg loggedInLinks"
                    >
                      {item.title}
                    </a>
                  ))}
                </>
              ) : (
                <a
                  className="body-lg signInText"
                  href={`${myAccountEULink}/auth`}
                >
                  Sign in or register
                </a>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MobileNavigation;
